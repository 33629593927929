const regexPatterns = {
  USER_PASSWORD: /^(?=.*[A-Za-z])(?=.*\d).{6,20}$/,
  USER_PHONENUMBER: /^[9876]\d{9}$/,
  USER_EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  DURATION_GLOBAL: /^[0-9]{1,2}:[0-5]{0,1}[0-9]{1}$|^[0-9]{1,2}h[0-5]{0,1}[0-9]{1}m$|^[0-9]{1,2}h$|^[0-5]{0,1}[0-9]{1}m$|^[0-9]{0,2}\.[0-9]{1,2}$/,
};

const userRoleConstants = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
};

const userStatusConstants = {
  ACTIVE: 1,
  INACTIVE: 2,
};

const projectStatusConstants = {
  ACTIVE: 1,
  INACTIVE: 2,
  COMPLETED: 3,
};

const taskStatusConstants = {
  'TO DO': 1,
  'IN PROGRESS': 2,
  COMPLETED: 3,
};

const pollingIntervalOfTwoMinutes = 1000 * 60 * 2;

const clientStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
};

// eslint-disable-next-line
export { regexPatterns, userRoleConstants, userStatusConstants, projectStatusConstants, taskStatusConstants, pollingIntervalOfTwoMinutes, clientStatus };
