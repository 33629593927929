import { useState } from 'react';
import ReportFilterFields from './ReportFilterFields';
import ReportTable from './ReportTable';

function ReportTableAndFilterFieldsContainer({
  customFields, customCheckedFields, setFilter, filtersData,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <ReportFilterFields
        setFilter={setFilter}
        customFields={customFields}
        filtersData={filtersData}
        customCheckedFields={customCheckedFields}
        setCurrentPage={setCurrentPage}
      />
      <ReportTable
        customCheckedFields={customCheckedFields}
        filtersData={filtersData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
}

export default ReportTableAndFilterFieldsContainer;
