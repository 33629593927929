import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from './Table';
import TableHeader from './TableHeader';
import { decryptRole } from '../../utils/cryptoUtils';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import LogHoursModal from '../Tasks/TaskDetails/LogHoursModal';

function Timesheet() {
  const { data: masterData } = useGetMasterDataQuery();
  const [filtersData, setFiltersData] = useState({});
  const [showLogHoursModal, setShowLogHoursModal] = useState(false);

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  return (
    <div className="row pt-0 mt-5">
      <div className="d-flex justify-content-between progress-button mt-0">
        <div>
          <h2 className="pb-0 mb-0 ms-0 pt-0">Timesheet</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-900">
              Timesheet
            </li>
          </ul>
        </div>
        <div className="header-buttons">
          <div className="btn-group" role="group" aria-label="Basic example">

            <button onClick={() => setShowLogHoursModal({})} type="button" className="btn btn-sm btn-primary"><i className="fa fa-download fa-plus me-1" />Log Time</button>
          </div>

        </div>
      </div>

      <div className="row mt-5 pt-0">
        <div className="col-md-12">
          <div className="card priority-card">
            <TableHeader
              masterData={masterData}
              roleMemo={roleMemo}
              setFiltersData={setFiltersData}
            />
            <Table
              filtersData={filtersData}
              masterData={masterData}
            />
          </div>
        </div>
      </div>
      {showLogHoursModal && (
        <LogHoursModal
          showModalBox={showLogHoursModal}
          setShowModalBox={setShowLogHoursModal}
          modalValues={{ modalType: 'addOnTimesheet' }}
          type="addOnTimesheet"
          masterData={masterData}
        />
      )}
    </div>
  );
}

export default Timesheet;
