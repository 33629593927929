import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Pagination from '../../custom/Pagination';
import { useGetReportsMutation } from '../../../Redux/services/report';

function ReportTable({
  customCheckedFields, filtersData, currentPage, setCurrentPage,
}) {
  const [fetchreport] = useGetReportsMutation();
  const [reportData, setReportsData] = useState({});
  const [totalCount, setTaskCount] = useState(0);
  const size = 5;
  const hasOnlyTaskIds = reportData?.data?.result[0] ? (Object.keys(reportData?.data?.result[0]).length === 1) && (Object.keys(reportData?.data?.result[0])[0] === 'taskId') : false;

  const fetchReports = async () => {
    try {
      const fields = customCheckedFields
        ?.filter((field) => field?.report)
        .map((field) => field.key);

      const filters = Object.keys(filtersData).filter((key) => {
        const value = filtersData[key];
        return value !== undefined && value !== null && value !== '' && (Array.isArray(value) ? value.length > 0 : true);
      }).reduce((obj, key) => {
        const object = { ...obj };
        object[key] = filtersData[key];
        return object;
      }, {});
      const response = await fetchreport({
        fields, filters, page: currentPage, size,
      });
      setReportsData(response?.data);
      setTaskCount(response?.data?.data?.totalRecords ?? 0);
    } catch (err) {
      window.toastr.error(err?.message ?? 'Something went wrong');
    }
  };

  useEffect(() => {
    fetchReports();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customCheckedFields, filtersData, currentPage, size]);

  // useEffect(() => {
  //   const initializeDataTable = () => {
  //     const table = document.getElementById('report-table');

  //     if (table && reportData?.data?.result?.length > 0) {
  //       window.postMessage({
  //         EventName: 'register-datatable',
  //         payload: 'report-table',
  //       }, '*');
  //     }
  //   };

  //   const timeoutId = setTimeout(initializeDataTable, 1000);

  //   return () => clearTimeout(timeoutId);
  // }, [reportData?.data]);

  const getTaskStatusBadgeClass = (taskStatus) => {
    switch (taskStatus) {
      case 'To do':
        return 'badge-light-info';
      case 'In Progress':
        return 'badge-light-warning';
      case 'Complete':
        return 'badge bs-yellow-light text-yellow';
      default:
        return 'badge-secondary';
    }
  };

  const renderTableCells = () => {
    const tableRows = [];
    if (reportData?.data?.result) {
      reportData?.data?.result.forEach((reportItem, index) => {
        const row = (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`${index}`}>
            {Object.keys(reportItem).map((key, keyIndex) => {
              const value = reportItem[key];
              const tdKey = `${key}_${index}_${keyIndex}`;

              if (key === 'taskId') return null;

              switch (key) {
                case 'taskStatus':
                  return (
                    <td key={tdKey}>
                      <span className={`badge fw-bold ${getTaskStatusBadgeClass(value)}`}>
                        {value}
                      </span>
                    </td>
                  );
                case 'uniqueTaskId':
                  return (
                    <td key={tdKey}>
                      <Link to={`/tasks/${value}`} className="text-gray-800 text-hover-primary">
                        {value}
                      </Link>
                    </td>
                  );
                case 'description':
                  return (
                    <td key={tdKey}>
                      <OverlayTrigger
                        key="right"
                        placement="top"
                        overlay={<Tooltip id="tooltip-right">{value}</Tooltip>}
                      >
                        <span className="comments-excerpt">{value}</span>
                      </OverlayTrigger>
                    </td>
                  );
                case 'projectName':
                  return (
                    <td key={tdKey}>
                      <strong className="text-gray-900">{value}</strong>
                    </td>
                  );
                case 'hours':
                  return (
                    <td key={tdKey}>
                      <strong className="text-gray-900">{value}</strong>
                    </td>
                  );
                default:
                  return <td style={{ textAlign: 'none' }} key={tdKey}>{value}</td>;
              }
            })}
          </tr>
        );
        tableRows.push(row);
      });
    }

    return tableRows;
  };

  const renderHeader = () => {
    if (customCheckedFields) {
      const tableHeader = customCheckedFields?.filter((item) => item?.report).map((value) => (
        <th
          key={value.id}
          className="min-w-125px text-start"
        >
          {value.label}
        </th>
      ));
      return tableHeader;
    }

    return null;

  };

  return (
    <div className="card" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <div className="card-header border-0 pt-6">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">Reports</span>
        </h3>
      </div>
      <div className="card-body pt-0 pb-4">
        <div className="table-responsive">
          {(reportData?.data) && (customCheckedFields)
            ? (
              <table
                className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7"
                id="report-table"
              >
                <thead>
                  <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                    {renderHeader()}
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold">
                  {renderTableCells()}
                </tbody>
              </table>
            )
            : <span className="spinner spinner-primary spinner-sm" />}
        </div>
        {(reportData?.data?.result?.length > 0 && !hasOnlyTaskIds)
          ? (
            <Pagination
              dataPerPage={size}
              totalData={totalCount}
              currentPage={currentPage}
              paginate={setCurrentPage}
            />
          )
          : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
      </div>
    </div>
  );
}

export default ReportTable;
