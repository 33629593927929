import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const timesheetService = createApi({
  reducerPath: 'timesheetService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  tagTypes: ['Timesheet'],
  //   refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getMonthlyTimesheet: builder.query({
      query: ({
        projects, month, users,
      }) => ({
        url: '/timesheet/monthly',
        method: 'post',
        data: { projects, month, users },
      }),
      providesTags: ['Timesheets'],
    }),
    getLogDetails: builder.query({
      query: ({
        project, user, date,
      }) => ({
        url: `/timesheet/log-hours-details?user=${user}&project=${project}&date=${date}`,
        method: 'get',
      }),
      invalidatesTags: ['LogDetails'],
    }),
  }),
});

export const {
  useGetMonthlyTimesheetQuery,
  useGetLogDetailsQuery,
} = timesheetService;
