import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const companyService = createApi({
  reducerPath: 'companyService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getClientsList: builder.query({
      query: ({
        filters, sortColumn = 'adminName', sortOrder = 'asc', page = 1, size = 5,
      }) => ({
        url: `/clients/filter/?page=${page}&size=${size}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
        method: 'post',
        data: filters,
      }),
      providesTags: ['Clients'],
    }),
    getClientById: builder.query({
      query: ({ id }) => ({
        url: `/clients/${id}`,
        method: 'get',
      }),
    }),
    addClient: builder.mutation({
      query: (data) => ({
        data,
        url: '/clients',
        method: 'post',
      }),
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation({
      query: ({ id, data }) => ({
        data,
        url: `/clients/${id}`,
        method: 'put',
      }),
      invalidatesTags: ['Clients'],
    }),

    deleteClient: builder.mutation({
      query: ({ id }) => ({
        url: `/clients/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['Clients'],
    }),
  }),

});

export const {
  useGetClientsListQuery,
  useGetClientByIdQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useAddClientMutation,
} = companyService;
