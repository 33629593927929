/* eslint-disable*/

import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import { useState } from 'react';
import moment from 'moment'; 
import { useGetMasterDataQuery } from '../../../Redux/services/masterData';
import useDebounce from '../../custom/useDebounce';
import { useGlobalSearchQuery } from '../../../Redux/services/globalSearch';

function ReportFilterFields({ setFilter, customFields, filtersData, setCurrentPage }) {
  const { data: masterData } = useGetMasterDataQuery();
  const [taskSearch, setTaskSearch] = useState('');
  const [inputsData, setInputsData] = useState({});
  const [taskId, setTaskId] = useState('');
  const [title, setTitle] = useState('');
  const [project, setProject] = useState('');
  const [taskStatus, setTaskStatus] = useState('');
  const [users, setUsers] = useState('');


  const taskDebounced = useDebounce({ value: taskSearch, delay: 500 });
  const { data: globalSearchData } = useGlobalSearchQuery({
    data: { keyword: taskDebounced },
  });

  const handleInputChange = (key, value) => {
    if (key === 'users' || key === 'taskStatus') {
      setInputsData((prev) => ({ ...prev, [key]: value?.map((item) => item.value) }));
    } else if (key === 'projectName' || key === 'uniqueTaskId' || key === 'title') {
      setInputsData((prev) => ({ ...prev, [key]: value?.value }));
    } else {
      setInputsData((prev) => ({ ...prev, [key]: value }));
    }
  };


  const getType = (val) => {
    if (val) {
      if (val.toLowerCase() === 'integer') {
        return 'number';
      }
      if (val.toLowerCase() === 'string') {
        return 'text';
      }
      return val;
    }
    return null;
  };

  const renderOptions = (label) => {
    if (label === 'Task No') {
      return  globalSearchData?.data?.tasks?.map((task) => ({
        label: task?.uniqueTaskId,
        value: task?.uniqueTaskId,
      }));
    }

    if (label === 'Project Name') {
      return globalSearchData?.data?.projects?.map((project) => ({
        label: project?.name,
        value: project?.name,
      }));
    }

    if (label === 'Task Name') {
      return globalSearchData?.data?.tasks?.map((task) => ({
        label: task?.title,
        value: task?.title,
      }));
    }
    
  }

  const handleClearFilters = () => {
    setFilter('');
    setInputsData('');
    setCurrentPage(1);
    setTaskId('');
    setProject('');
    setTitle('');
    setTaskStatus('');
    setUsers('');
  }

  const handleTypaheadSelectChange = (key, value) => {
    if (key === 'projectName') {
      setProject(value);
    } else if (key === 'title') {
      setTitle(value);
    } else if (key === 'uniqueTaskId') {
      setTaskId(value);
    }
  };

  const handleTypaheadSelectValues = (key) => {
    if (key === 'projectName') {
      return project;
    } else if (key === 'title') {
      return title;
    } else if (key === 'uniqueTaskId') {
      return taskId;
    }
  };

  const getFilterInputs = () => {
    const filters = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    Object.entries(customFields).forEach(([key, filter]) => {
      if (!filter?.filterChecked) {
        delete filtersData[key];
        return;
      };

      let options = [];
      if (masterData?.data) {
        if(key === 'taskStatus') {
          options = masterData?.data['taskStatuses']?.map((item) => {
            return {
              label: item?.name,
              value: item?.id
            }
          })
        }
        if(key === 'users') {
          options = masterData?.data[key]?.map((item) => {
            return {
              label: item?.fullName,
              value: item?.id
            }
          })
        }

      }
      let filterElement;

      switch (filter?.inputType?.toLowerCase()) {
        case 'select':
          if(key === 'users') {
           filterElement = (<Select
              key={key}
              name="colors" className="filterSelect" classNamePrefix="select" value={users} options={options} isMulti={filter.multiselect ?? true} onChange={(val) => {
                setUsers(val);
                handleInputChange(key, val)
              }} isClearable />)
          }
           if (key === 'taskStatus') {
            filterElement = (
              <Select
                key={key}
                name="colors" className="filterSelect" classNamePrefix="select" value={taskStatus} options={options} isMulti={filter.multiselect ?? true} onChange={(val) => {
                  setTaskStatus(val);
                  handleInputChange(key, val)
                }} isClearable />
            );
          }
          break;
        case 'date':
          filterElement = (
            <DateRangePicker
              onApply={(e, j) => {
                handleInputChange(key, `${moment(j.startDate).format('L')}-${moment(j.endDate).format('L')}`);
              }}
              initialSettings={{
                startDate: new Date(), endDate: new Date(), maxDate: new Date(currentDate.getFullYear(), currentMonth + 1, 0), locale: { cancelLabel: 'Clear' },
              }}
              onCancel={() => { handleDatePickerCancel('', key); }}
            >
              <p className="form-control text-sm-left">{inputsData?.[key] ? inputsData?.[key] : 'Select date'}</p>
            </DateRangePicker>
          );
          break;
        case 'typeahead':
            filterElement = (
              <Select
                key={key}
                options={renderOptions(filter?.label)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={handleTypaheadSelectValues(key)}
                isClearable
                onChange={(val) => {
                  handleTypaheadSelectChange(key, val);
                  handleInputChange(key, val)
                }}
                onInputChange={(input) => setTaskSearch(input)}
                placeholder="Search..."
              />
            );
          break;
        default:
          filterElement = (
            <input
              min={filter.min ?? null}
              max={filter.max ?? null}
              type={getType(filter.type)}
              value={inputsData?.[key]}
              className="form-control "
              onChange={(e) => {
                let { value } = e.target;
                if (filter.type.toLowerCase() === 'integer' || filter.type.toLowerCase() === 'number') value = parseInt(e.target.value, 10);
                handleInputChange(key, value);
              }}
            />
          );
      }

      filters.push(
        <div key={filter.label} className="col-lg-3">
          <label className="form-label w-250px">{filter.label === 'Project Name' || filter.label === 'Project #' ? 'Project' : filter.label}</label>
          {filterElement}
        </div>,
      );
    });
    return filters;
  };

  const handleDatePickerCancel = (value, fieldName) => {
    handleInputChange(fieldName, value);
  };

  return (
    <div className="row gy-5 g-xl-10">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="card card-flush mb-5">
          <div className="card-header py-4 minimize">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-800">Search Filters</span>
            </h3>
            <div className="card-toolbar mt-0">
              <button
                type="button"
                className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize"
                aria-label="button"
              >
                <i className="fas fa-minus" />
              </button>
            </div>
          </div>

          <div className="card-body border-0 py-0 minimize reports-filter-section">
            {
                Object.values(customFields).filter((field) => field?.filterChecked).length > 0
                  ? (
                    <>
                      <div className="row mb-5 horizontal-scrollable">
                        {getFilterInputs()}
                      </div>
                      <div className="d-flex justify-content-end p-5 ">
                      <button type="button"
                        onClick={() => {
                          const filters = { ...inputsData };
                          setFilter(filters);
                          setCurrentPage(1);
                        }}
                        className="btn btn-dark fs-6">Filter
                      </button>
                      <button onClick={handleClearFilters} type="button" className="btn btn-label-secondary">Clear</button>
                      </div>
                    </>
                  )
                  : <div className="d-flex justify-content-center pb-20">No Filters Selected</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportFilterFields;
