import Select from 'react-select';

import {
  Form, Modal, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import FlatPickr from 'react-flatpickr';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useAddLogHoursMutation, useEditLogHoursMutation, useGetTasksListWithFiltersQuery } from '../../../Redux/services/task';
import { regexPatterns } from '../../../utils/constant';

function LogHoursModal({
  showModalBox, setShowModalBox, modalValues, type, masterData,
}) {
  const [addLogHours] = useAddLogHoursMutation();
  const [editLogHours] = useEditLogHoursMutation();
  const user = useSelector((state) => state?.user);

  const {
    register, handleSubmit, formState: { errors }, control, watch,
  } = useForm({
    defaultValues: {
      hours: modalValues.hours ?? '01:00',
      logDate: modalValues.date ? moment(modalValues.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : null,
      logDescription: modalValues.description ?? '',
    },
  });
  const project = watch('project');
  const { data: tasksData } = useGetTasksListWithFiltersQuery({
    page: 1,
    size: 1000,
    filters: {
      project: [project?.value],
    },
  }, {
    skip: type !== 'addOnTimesheet',
  });

  const onSubmit = async (data) => {
    const reqBody = {
      hours: data.hours,
      date: moment(data.logDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      description: data.logDescription,
    };

    let response;
    if (modalValues.modalType === 'add' || modalValues.modalType === 'addOnTimesheet') {
      response = await addLogHours({ id: type === 'addOnTimesheet' ? watch('taskNo')?.value : modalValues?.taskId, data: reqBody });
    } else if (modalValues.modalType === 'update') {
      response = await editLogHours({
        id: modalValues?.taskId,
        logId: modalValues?.id,
        data: reqBody,
      });
    }

    if (response?.data?.success) {
      window.toastr.success(response?.data?.message);
      setShowModalBox(false);
    } else {
      window.toastr.error(response?.data?.message);
    }
  };

  const handleModalClose = () => {
    Swal.fire({
      text: 'Are you sure you would like to cancel?',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, return',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-active-light',
      },
    })
      .then((result) => {
        if (result.value) {
          setShowModalBox(false);
        }
      })
      .catch((error) => {
        window.toastr.error(error?.message ?? 'Something went wrong!');
      });
  };

  const renderProjects = () => masterData?.data?.projects?.map((projectData) => ({
    label: projectData?.name,
    value: projectData?.id,
  }));

  const renderTasks = () => tasksData?.data?.tasks?.map((task) => ({
    label: task?.title,
    value: task?.id,
  }));

  return (
    <Modal show={showModalBox} centered>
      <Modal.Body className="modal-body scroll-y mb-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div id="location">
            <div className="applicant-fileds">
              <div className="form-group">
                <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                  <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-2">
                    <div className="form-label mb-2">Log hours for this task</div>
                    <div className="card-body p-2">
                      <div className="card-body d-flex flex-column pb-0">
                        <div className="d-flex flex-column gap-5 gap-md-7">
                          <div className="row">
                            {type === 'addOnTimesheet' && (
                            <div className="col-xl-6 col-lg-6 col-6">
                              <label className="form-label" htmlFor="task-no">Project #</label>
                              <Controller
                                name="project"
                                rules={{
                                  required: { value: true, message: 'Required' },
                                }}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={renderProjects()}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    placeholder="Select"
                                  />
                                )}
                              />
                              {errors?.project
                                && (
                                <div className="text-danger small">
                                  {errors.project?.message}
                                </div>
                                )}
                            </div>
                            )}
                            {type !== 'addOnTimesheet' ? (
                              <div className="col-xl-6 col-lg-6 col-6">
                                <label className="form-label" htmlFor="task-no">Task #</label>
                                <input className="form-control" id="task-no" name="taskNo" defaultValue={modalValues?.taskNo} disabled autoComplete="off" />
                              </div>
                            )
                              : (
                                <div className="col-xl-6 col-lg-6 col-6">
                                  <label className="form-label" htmlFor="task-no">Task #</label>
                                  <Controller
                                    name="taskNo"
                                    rules={{
                                      required: { value: true, message: 'Required' },
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={renderTasks()}
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                        isDisabled={!project}
                                        placeholder="Select"
                                      />
                                    )}
                                  />
                                </div>
                              )}
                            { type !== 'addOnTimesheet' && (
                            <div className="col-xl-6 col-lg-6 col-6">
                              <label className="form-label" htmlFor="assignee">Assignee</label>
                              <input className="form-control" id="assignee" autoComplete="off" defaultValue={modalValues?.assigneeName} name="assigneeName" disabled />
                            </div>
                            )}

                          </div>
                          {type === 'addOnTimesheet' && (
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-12">
                              <label className="form-label" htmlFor="assignee">Assignee</label>
                              <input className="form-control" id="assignee" autoComplete="off" defaultValue={user?.fullName} name="assigneeName" disabled />
                            </div>
                          </div>
                          )}

                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-6">
                              <OverlayTrigger placement="top" overlay={<Tooltip> Formats supported: 02:30, 2h30m, 2h, 30m, 2.5 </Tooltip>}>
                                <label className="required form-label" htmlFor="log-hours">Log Hours <i className="fas fa-exclamation-circle ms-1 fs-6" /></label>
                              </OverlayTrigger>
                              <input
                                type="text"
                                id="log-hours"
                                className="form-control"
                                placeholder="HH:MM"
                                disabled={modalValues.modalType === 'view'}
                                {...register('hours', {
                                  required: { value: true, message: 'Required' },
                                  pattern: {
                                    value: regexPatterns.DURATION_GLOBAL,
                                    message: 'Please enter a valid time format',
                                  },
                                })}
                              />
                              {errors?.hours && <div className="text-danger small">{errors.hours?.message}</div>}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-6">
                              <label className="required form-label" htmlFor="log-date">Log Date</label>
                              <Controller
                                name="logDate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <FlatPickr
                                    id="log-date"
                                    className="form-control"
                                    value={value}
                                    options={{ dateFormat: 'd-m-Y', animate: true, maxDate: 'today' }}
                                    disabled={modalValues.modalType === 'view'}
                                    onChange={(_, currentdateString) => onChange(currentdateString)}
                                  />
                                )}
                                rules={{ required: 'Required' }}
                              />
                              {errors?.logDate && <div className="text-danger small">{errors.logDate.message}</div>}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-12">
                              <label className="required form-label" htmlFor="log-description">Log Description</label>
                              <textarea
                                className="form-control"
                                id="log-description"
                                placeholder="Type your description here..."
                                disabled={modalValues.modalType === 'view'}
                                {...register('logDescription', {
                                  required: { value: true, message: 'Required' },
                                  validate: {
                                    nonEmpty: (value) => value.trim().length > 0 || 'Description cannot be empty',
                                    maxLength: (value) => value.trim().length <= 500 || 'Description cannot exceed 500 characters',
                                  },
                                })}
                              />
                              {errors?.logDescription && <div className="text-danger small">{errors.logDescription?.message}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pt-8 mt-10">
            <button
              type="button"
              className="btn btn-light me-3 btn-active-light-primary"
              onClick={modalValues.modalType === 'view'
                ? () => setShowModalBox(false) : handleModalClose}
            >
              {modalValues.modalType === 'view' ? 'Close' : 'Cancel'}
            </button>
            {modalValues.modalType !== 'view' ? (
              <button className="btn btn-primary me-4" id="savebutton" type="submit" disabled={modalValues.modalType === 'view'}>
                <span>Save</span>
              </button>
            ) : null}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default LogHoursModal;
