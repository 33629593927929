import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';
import { projectsApi } from './project';
import { timesheetService } from './timesheet';
import { notificationService } from './notifications';

const apiHost = process.env.REACT_APP_API_HOST;

export const taskService = createApi({
  reducerPath: 'taskService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return headers;
      }
      return {};
    },
  }),
  tagTypes: ['Tasks', 'Task', 'Comments', 'ActivityLog', 'LogHours'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getTasksListWithFilters: builder.query({
      query: ({
        filters, page = 1, size = 5, sortColumn = 'name', sortOrder = 'asc', weekly = false,
      }) => ({
        data: filters,
        url: `/tasks/filter?page=${page}&size=${size}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&weekly=${weekly}`,
        method: 'post',
      }),
      providesTags: ['Tasks'],
    }),
    getTaskById: builder.query({
      query: ({ id }) => ({
        url: `/tasks/${id}`,
        method: 'get',
      }),
      providesTags: ['Task'],
    }),
    addTask: builder.mutation({
      query: (data) => ({
        data,
        url: '/tasks',
        method: 'post',
      }),
      invalidatesTags: ['Tasks'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            projectsApi.util.invalidateTags(['Project']),
          );
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    editTask: builder.mutation({
      query: ({ data, id }) => ({
        data,
        url: `/tasks/${id}`,
        method: 'put',
      }),
      invalidatesTags: ['Task', 'ActivityLog'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `/tasks/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['Tasks'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    getTaskIdfromUniqueTaskId: builder.query({
      query: ({ id }) => ({
        url: `/tasks/${id}/unique-id`,
        method: 'get',
      }),
    }),
    getAllComments: builder.query({
      query: ({ id }) => ({
        url: `/tasks/${id}/comments`,
        method: 'get',
      }),
      providesTags: ['Comments'],
      transformResponse: (response) => ({
        ...response,
        data: response?.data.reverse(),
      }),
    }),
    addComment: builder.mutation({
      query: ({ id, data }) => ({
        data,
        url: `/tasks/${id}/comments`,
        method: 'post',
      }),
      invalidatesTags: ['Comments'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    getTaskActivityLog: builder.query({
      query: ({ id }) => ({
        url: `/tasks/${id}/activity-log?view=web`,
        method: 'get',
      }),
      providesTags: ['ActivityLog'],
    }),
    getLogHours: builder.query({
      query: ({ id }) => ({
        url: `/tasks/${id}/log-hours`,
        method: 'get',
      }),
      providesTags: ['LogHours'],
    }),
    addLogHours: builder.mutation({
      query: ({ id, data }) => ({
        data,
        url: `/tasks/${id}/log-hours`,
        method: 'post',
      }),
      invalidatesTags: ['LogHours'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            timesheetService.util.invalidateTags(['Timesheets']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    editLogHours: builder.mutation({
      query: ({ id, logId, data }) => ({
        data,
        url: `/tasks/${id}/log-hours/${logId}`,
        method: 'put',
      }),
      invalidatesTags: ['LogHours'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            timesheetService.util.invalidateTags(['Timesheets']),
          );
          dispatch(
            timesheetService.util.invalidateTags(['LogDetails']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    deleteLogHours: builder.mutation({
      query: ({ taskId, logId }) => ({
        url: `/tasks/${taskId}/log-hours/${logId}`,
        method: 'delete',
      }),
      invalidatesTags: ['LogHours'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            timesheetService.util.invalidateTags(['Timesheets']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    uploadAttachments: builder.mutation({
      query: ({ data }) => {
        const attachments = new FormData();
        attachments.append('attachments', data[0]);
        return {
          url: '/attachments',
          method: 'post',
          data: attachments,
          formData: true,
        };
      },
    }),
  }),

});

export const {
  useGetTasksListWithFiltersQuery, useAddTaskMutation,
  useEditTaskMutation, useGetTaskIdfromUniqueTaskIdQuery,
  useGetTaskByIdQuery, useGetAllCommentsQuery, useAddCommentMutation,
  useGetTaskActivityLogQuery, useUploadAttachmentsMutation, useDeleteTaskMutation,
  useGetLogHoursQuery, useDeleteLogHoursMutation, useAddLogHoursMutation, useEditLogHoursMutation,
} = taskService;
