import { Modal } from 'react-bootstrap';

function SettingsModal({ onHide }) {
  return (
    <Modal show centered onHide={onHide} size="lg">

      <Modal.Header className="pb-2" closeButton>
        <Modal.Title>
          <h2>Configuration of Company Name</h2>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body  mb-4">
        <h5 className="mb-6">Permissions</h5>

        <div className="d-flex flex-stack border border-gray-300 border-dashed rounded py-6 ps-6">
          <div className="d-flex align-items-center me-5">

            <div className="me-5">
              <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-7">Reports</a>

            </div>
          </div>
          <div className="text-gray-500 fw-bold fs-7 text-end">
            <div className="form-check form-switch form-check-custom form-check-solid me-10">
              <input className="form-check-input h-20px w-30px" type="checkbox" value="" id="flexSwitch20x30" />
            </div>

          </div>
        </div>

        <div className="d-flex flex-stack border border-gray-300 border-dashed rounded py-6 ps-6 mt-4">
          <div className="d-flex align-items-center me-5">

            <div className="me-5">
              <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-7">Timesheet</a>

            </div>
          </div>
          <div className="text-gray-500 fw-bold fs-7 text-end">
            <div className="form-check form-switch form-check-custom form-check-solid me-10">
              <input className="form-check-input h-20px w-30px" type="checkbox" value="" id="flexSwitch20x30" />
            </div>

          </div>
        </div>
      </div>

    </Modal>
  );
}

export default SettingsModal;
