import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const reportService = createApi({
  reducerPath: 'reportService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  tagTypes: ['Reports'],
  //   refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getReports: builder.mutation({
      query: ({
        fields, filters, page, size,
      }) => ({
        url: `/reports?page=${page}&size=${size}`,
        method: 'post',
        data: { fields, filters },
      }),
      providesTags: ['Reports'],
    }),
    getReportFields: builder.query({
      query: () => ({
        url: '/reports/fields',
        method: 'get',
      }),
    }),
    getTemplates: builder.query({
      query: () => ({
        url: '/reports/templates',
        method: 'get',
      }),
      providesTags: ['Templates'],
    }),
    addTemplate: builder.mutation({
      query: ({ data }) => ({
        url: '/reports/templates',
        method: 'post',
        data,
      }),
      invalidatesTags: ['Templates'],
    }),
    updateTemplateConfiguration: builder.mutation({
      query: ({ id, data }) => ({
        url: `/reports/${id}/templates-configurations`,
        method: 'put',
        data,
      }),
      invalidatesTags: ['Templates'],
    }),
    getTemplateConfiguration: builder.query({
      query: ({ id }) => ({
        url: `/reports/${id}/templates-configurations`,
        method: 'get',
      }),
      keepUnusedDataFor: 0,
    }),
    deleteTemplateConfiguration: builder.mutation({
      query: ({ id }) => ({
        url: `/reports/${id}/templates-configurations`,
        method: 'delete',
      }),
      invalidatesTags: ['Templates'],
      keepUnusedDataFor: 0,
    }),
    exportReport: builder.mutation({
      query: ({ fields, filters }) => ({
        url: '/reports/export-report',
        method: 'post',
        data: { fields, filters },
      }),
    }),
  }),
});

export const {
  useGetReportsMutation,
  useGetReportFieldsQuery,
  useGetTemplatesQuery,
  useUpdateTemplateConfigurationMutation,
  useGetTemplateConfigurationQuery,
  useAddTemplateMutation,
  useDeleteTemplateConfigurationMutation,
  useExportReportMutation,
} = reportService;
