import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useState } from 'react';
import LogHoursModal from '../Tasks/TaskDetails/LogHoursModal';
import { useGetLogDetailsQuery } from '../../Redux/services/timesheet';
import { useDeleteLogHoursMutation } from '../../Redux/services/task';

function LogDetails({ showlogDetailsModal, onHide, dataForLogDetails }) {
  const { data: logDetailsData } = useGetLogDetailsQuery(dataForLogDetails);
  const [deleteLogDetails] = useDeleteLogHoursMutation();
  const [logHoursModalValues, setLogHoursModalValues] = useState({});
  const [showLogHoursModal, setShowLogHoursModal] = useState(false);
  const user = useSelector((state) => state.user);

  const handleDeletLogDetails = ({ taskId, logId }) => {
    Swal.fire({
      text: 'Are you sure you want to delete this log?',
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#161221',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
        cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteLogDetails({ taskId, logId });
          if (response?.data?.success) {
            window.toastr.success(response.data.message);
          } else {
            window.toastr.error(response?.error?.data?.message ?? 'Could not delete log');
          }

        } catch (error) {
          window.toastr.error(error?.data?.message ?? 'Something went wrong');
        }
      }
    });
  };

  return (
    <Modal
      show={showlogDetailsModal}
      onHide={onHide}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          View log details
          <br />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users" style={{ minWidth: '750px', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
            <thead>
              <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-50px w-25">User</th>
                <th className="min-w-50px w-25">Date</th>
                <th className="min-w-50px w-25">Task ID</th>
                <th className="min-w-90px w-100">Description</th>
                <th className="min-w-50px w-25">Hours</th>
                <th className="min-w-50px w-25">Action</th>
              </tr>
            </thead>

            <tbody className="text-gray-600 fw-semibold">

              {logDetailsData?.data?.logHourDetailsForUser?.map((response, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td>{response?.loggedBy}</td>
                  <td>{moment(response?.date, 'YYYY-MM-DD').format('DD-MMM YYYY')}</td>
                  <td>{response?.uniqueTaskId}</td>
                  <td className="report-log-hours min-w-100px">{response?.description}</td>
                  <td>{response?.hours}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-icon btn-light-primary"
                      type="button"
                      onClick={() => {
                        setLogHoursModalValues({
                          id: response?.id, taskId: response?.taskId, assigneeName: response?.loggedBy, assignee_id: response?.userID, date: response?.date, hours: response?.hours, description: response?.description, taskNo: response?.uniqueTaskId, modalType: parseInt(response?.userId, 10) === parseInt(user?.userId, 10) ? 'update' : 'view',
                        });
                        setShowLogHoursModal(true);
                      }}
                    >
                      {' '}
                      <i className={`${parseInt(user?.userId, 10) === parseInt(response?.userId, 10) ? 'fas fa-pen' : 'far fa-eye'}`} />{' '}
                    </button>
                    {(parseInt(response?.userId, 10) === parseInt(user?.userId, 10))
                      ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-icon btn-light-primary delete-row mobile-delete-row ms-1"
                          onClick={() => handleDeletLogDetails({
                            taskId: response?.taskId, logId: response?.userId,
                          })}
                          aria-label="button"
                        >
                          <i className="fa fa-trash" title="Delete Log Hours" />
                        </button>
                      ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pe-5" style={{ textAlign: 'right', padding: '10px', fontWeight: 'bold' }}>
          Total <span className="ps-2">{logDetailsData?.data?.totalHours ?? 0}</span>
        </div>
        {showLogHoursModal && (
        <LogHoursModal
          showModalBox={showlogDetailsModal}
          setShowModalBox={setShowLogHoursModal}
          modalValues={logHoursModalValues}
        />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default LogDetails;
