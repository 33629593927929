import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearAuth } from '../../Redux/slices/auth';
import { decryptRole } from '../../utils/cryptoUtils';
import { userRoleConstants } from '../../utils/constant';

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    dispatch(clearAuth());
    navigate('/login');
  };

  return (
    <div>
      <div id="sidenav-overlay" />
      <div className="sidebar p-2 py-md-3 sidebar-mini">
        <div className="container-fluid">
          <div className="title-text d-flex align-items-center mb-0 mt-1">
            <h4 className="sidebar-title mb-0 flex-grow-1 fs-1">
              <span className="sm-txt logo-collapsed">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo.png`}
                  alt="Mind IT Systems"
                  width="40px"
                  className="theme-light-show"
                />
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo-white.png`}
                  alt="Mind IT Systems"
                  width="40px"
                  className="theme-dark-show"
                />
              </span>
              <span
                className="large-logo"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/workeazy-light-logo.png`}
                  alt="Mind IT Systems"
                  width="135px"
                  className="ms-1 theme-light-show"
                />
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/workeazy-dark-logo.png`}
                  alt="Mind IT Systems"
                  width="135px"
                  className="ms-1 theme-dark-show"
                />
              </span>
            </h4>
          </div>
          <div className="main-menu flex-grow-1">
            <ul className="menu-list">
              <li>
                <Link className="m-link" to="/">
                  <svg
                    width="18"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                    <path
                      className="fill-secondary"
                      fillRule="evenodd"
                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                    />
                  </svg>
                  <span className="ms-2">Home</span>
                  <span className="highlight">Home</span>
                </Link>
              </li>
              <li>
                <Link className="m-link" to="/projects">
                  <svg width="18" fill="currentColor" viewBox="0 0 16 16">
                    <path
                      className="fill-secondary"
                      d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
                    />
                    <path
                      d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
                    />
                  </svg>
                  <span className="ms-2">Projects</span>
                  <span className="highlight">Projects</span>
                </Link>
              </li>
              <li>
                <Link className="m-link" to="/tasks">
                  <svg width="21" fill="currentColor" viewBox="0 0 31 31">
                    <path stroke="#d34d04" strokeWidth="0.2" className="fill-secondary" d="M28,10H12a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V12A2,2,0,0,0,28,10ZM12,28V12H28V28Z" />
                    <path stroke="#000" strokeWidth="0.4" d="M7,20H4V4H20V7a1,1,0,0,0,2,0V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H7a1,1,0,0,0,0-2Z" />
                  </svg>
                  <span className="ms-2">Tasks</span>
                  <span className="highlight">Tasks</span>
                </Link>
              </li>
              { (roleMemo?.id !== userRoleConstants.USER) ? (
                <li>
                  <Link className="m-link" to="/reports">
                    <svg width="18" fill="currentColor" viewBox="0 0 16 16">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H1V15H16V16H0V0Z" />
                      <path
                        className="fill-secondary"
                        d="M10.1465 3.14645C10.0527 3.24021 10 3.36739 10 3.5C10 3.63261 10.0527 3.75979 10.1465 3.85355C10.2402 3.94732 10.3674 4 10.5 4H13.445L9.96301 8.256L7.35401 5.646C7.30288 5.59492 7.24129 5.55554 7.17347 5.53055C7.10565 5.50557 7.03323 5.49559 6.96118 5.50129C6.88914 5.50699 6.81918 5.52825 6.75614 5.56359C6.6931 5.59893 6.63847 5.64751 6.59601 5.706L2.59601 11.206C2.52384 11.3134 2.4961 11.4446 2.51862 11.572C2.54114 11.6994 2.61217 11.8131 2.71678 11.8892C2.82139 11.9653 2.95144 11.9979 3.0796 11.9802C3.20775 11.9624 3.32403 11.8957 3.40401 11.794L7.06001 6.767L9.64701 9.354C9.69641 9.4033 9.75557 9.44172 9.82071 9.46678C9.88585 9.49184 9.95551 9.50299 10.0252 9.4995C10.0949 9.49602 10.1631 9.47798 10.2254 9.44655C10.2877 9.41511 10.3428 9.37099 10.387 9.317L14 4.9V7.5C14 7.63261 14.0527 7.75979 14.1465 7.85355C14.2402 7.94732 14.3674 8 14.5 8C14.6326 8 14.7598 7.94732 14.8536 7.85355C14.9473 7.75979 15 7.63261 15 7.5V3.5C15 3.36739 14.9473 3.24021 14.8536 3.14645C14.7598 3.05268 14.6326 3 14.5 3H10.5C10.3674 3 10.2402 3.05268 10.1465 3.14645Z"
                      />
                    </svg>
                    <span className="ms-2">Report</span>
                    <span className="highlight">Report</span>
                  </Link>
                </li>
              ) : null}
              {roleMemo?.id !== userRoleConstants.USER
                && (
                <li>
                  <Link className="m-link" to="/users">
                    <svg width="18" fill="currentColor" viewBox="0 0 16 16">
                      <path className="fill-secondary" d="M15 14C15 14 16 14 16 13C16 12 15 9 11 9C7 9 6 12 6 13C6 14 7 14 7 14H15ZM7.022 13C7.01461 12.999 7.00727 12.9976 7 12.996C7.001 12.732 7.167 11.966 7.76 11.276C8.312 10.629 9.282 10 11 10C12.717 10 13.687 10.63 14.24 11.276C14.833 11.966 14.998 12.733 15 12.996L14.992 12.998C14.9874 12.9988 14.9827 12.9995 14.978 13H7.022ZM11 7C11.5304 7 12.0391 6.78929 12.4142 6.41421C12.7893 6.03914 13 5.53043 13 5C13 4.46957 12.7893 3.96086 12.4142 3.58579C12.0391 3.21071 11.5304 3 11 3C10.4696 3 9.96086 3.21071 9.58579 3.58579C9.21071 3.96086 9 4.46957 9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7ZM14 5C14 5.39397 13.9224 5.78407 13.7716 6.14805C13.6209 6.51203 13.3999 6.84274 13.1213 7.12132C12.8427 7.3999 12.512 7.62087 12.1481 7.77164C11.7841 7.9224 11.394 8 11 8C10.606 8 10.2159 7.9224 9.85195 7.77164C9.48797 7.62087 9.15725 7.3999 8.87868 7.12132C8.6001 6.84274 8.37913 6.51203 8.22836 6.14805C8.0776 5.78407 8 5.39397 8 5C8 4.20435 8.31607 3.44129 8.87868 2.87868C9.44129 2.31607 10.2044 2 11 2C11.7956 2 12.5587 2.31607 13.1213 2.87868C13.6839 3.44129 14 4.20435 14 5Z" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.216 14C5.06776 13.6878 4.99382 13.3455 5 13C5 11.645 5.68 10.25 6.936 9.28C6.30909 9.08684 5.65595 8.99237 5 9C1 9 0 12 0 13C0 14 1 14 1 14H5.216Z" />
                      <path d="M4.5 8C5.16304 8 5.79893 7.73661 6.26777 7.26777C6.73661 6.79893 7 6.16304 7 5.5C7 4.83696 6.73661 4.20107 6.26777 3.73223C5.79893 3.26339 5.16304 3 4.5 3C3.83696 3 3.20107 3.26339 2.73223 3.73223C2.26339 4.20107 2 4.83696 2 5.5C2 6.16304 2.26339 6.79893 2.73223 7.26777C3.20107 7.73661 3.83696 8 4.5 8Z" />
                    </svg>
                    <span className="ms-2">Users</span>
                    <span className="highlight">Users</span>
                  </Link>
                </li>
                )}
              <li>
                <Link className="m-link" to="/kanban">
                  <svg fill="currentColor" viewBox="0 0 21 24" width="23">
                    <path d="M7,10H9A1,1,0,0,0,9,8H7a1,1,0,0,0,0,2Z" className="fill-secondary" strokeWidth="0.5" />
                    <path d="M 17 4 H 11 V 3 a 1 1 0 0 0 -2 0 V 4 H 3 A 1 1 0 0 0 2 5 V 17 a 3 3 0 0 0 1 1 H 9 l -3 2 a 1 1 0 0 0 0 1 a 1 1 0 0 0 1 0 L 10 18 V 21 a 1 1 0 0 0 1 0 V 18 l 2 3 a 1 1 0 0 0 1 0 a 1 1 0 0 0 0 -1 L 12 18 H 17 a 3 3 0 0 0 1 -1 V 5 A 1 1 0 0 0 17 4 Z M 17 17 H 3 V 5 H 17 Z" />
                    <path d="M7,14h6a1,1,0,0,0,0-2H7a1,1,0,0,0,0,2Z" className="fill-secondary" strokeWidth="0.1" />
                  </svg>
                  <span className="ms-2">Kanban Board</span>
                  <span className="highlight">Kanban Board</span>
                </Link>
              </li>
              <li>
                <Link className="m-link" to="/timesheet">
                  <svg version="1.1" viewBox="0 0 28 28" fill="currentColor" width="20">
                    <path d="M14.5 27C7.607 27 2 21.393 2 14.5c0-3.291 1.334-6.512 3.661-8.839a.999.999 0 1 1 1.414 1.414A10.576 10.576 0 0 0 4 14.5C4 20.29 8.71 25 14.5 25S25 20.29 25 14.5c0-5.453-4.177-9.948-9.5-10.453V9a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1C21.393 2 27 7.607 27 14.5S21.393 27 14.5 27z" />
                    <path d="M8.793 10.207a.999.999 0 1 1 1.414-1.414l5.707 4.293a2 2 0 1 1-2.828 2.828l-4.293-5.707z" className="currentColor" />
                  </svg>
                  <span className="ms-2">Timesheet</span>
                  <span className="highlight">Timesheet</span>
                </Link>
              </li>
              {roleMemo?.id === userRoleConstants.SUPER_ADMIN
                && (
                <li>
                  <Link className="m-link" to="/companies">
                    <svg className="avatar md" viewBox="0 0 16 16" width="21" fill="currentColor">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.763 0.0750045C14.8354 0.119799 14.8952 0.18235 14.9367 0.256728C14.9782 0.331105 15 0.414844 15 0.500005V15.5C15 15.6326 14.9473 15.7598 14.8536 15.8536C14.7598 15.9473 14.6326 16 14.5 16H11.5C11.3674 16 11.2402 15.9473 11.1464 15.8536C11.0527 15.7598 11 15.6326 11 15.5V14H10V15.5C10 15.6326 9.94732 15.7598 9.85355 15.8536C9.75979 15.9473 9.63261 16 9.5 16H0.5C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526784 15.7598 0 15.6326 0 15.5V10C7.96467e-05 9.89511 0.0331481 9.79289 0.0945249 9.70783C0.155902 9.62276 0.242478 9.55915 0.342 9.526L6 7.64V4.5C6 4.40723 6.02582 4.31628 6.07456 4.23734C6.12331 4.15839 6.19305 4.09457 6.276 4.053L14.276 0.0530045C14.3523 0.0148103 14.4371 -0.00321934 14.5224 0.00063155C14.6076 0.00448244 14.6904 0.0300857 14.763 0.0750045ZM6 8.69401L1 10.36V15H6V8.69401ZM7 15H9V13.5C9 13.3674 9.05268 13.2402 9.14645 13.1465C9.24021 13.0527 9.36739 13 9.5 13H11.5C11.6326 13 11.7598 13.0527 11.8536 13.1465C11.9473 13.2402 12 13.3674 12 13.5V15H14V1.309L7 4.809V15Z" fill="black" />
                      <path className="fill-secondary" d="M2 11H3V12H2V11ZM4 11H5V12H4V11ZM2 13H3V14H2V13ZM4 13H5V14H4V13ZM8 9H9V10H8V9ZM10 9H11V10H10V9ZM8 11H9V12H8V11ZM10 11H11V12H10V11ZM12 9H13V10H12V9ZM12 11H13V12H12V11ZM8 7H9V8H8V7ZM10 7H11V8H10V7ZM12 7H13V8H12V7ZM8 5H9V6H8V5ZM10 5H11V6H10V5ZM12 5H13V6H12V5ZM12 3H13V4H12V3Z" fill="black" />
                    </svg>
                    <span className="ms-2">Companies</span>
                    <span className="highlight">Companies</span>
                  </Link>
                </li>
                )}
            </ul>
          </div>
          <ul className="menu-list nav navbar-nav flex-row text-center">
            <li className="nav-item flex-fill p-2">
              <Link to="/login" onClick={handleLogout} className="d-inline-block w-100 color-400" href="/login" aria-label="Logout" title="Logout">
                <svg width="18" fill="currentColor" viewBox="0 0 16 16">
                  <path stroke="#000" strokeWidth="0.4" d="M7.5 1v7h1V1h-1z" />
                  <path stroke="#d34d04" strokeWidth="0.7" className="fill-secondary" d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg>
              </Link>
            </li>
          </ul>

        </div>
      </div>
    </div>
  );
}
export default Sidebar;
