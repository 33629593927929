import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { setAuth } from '../../../Redux/slices/auth';
import { userRoleConstants } from '../../../utils/constant';
import { decryptRole } from '../../../utils/cryptoUtils';

function PR({ children }) {
  const { pathname } = useLocation();

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.auth);

  if (authStatus.token && authStatus.isAuthenticated) {
    if ((roleMemo.id === userRoleConstants.USER && pathname === '/users') || (roleMemo.id === userRoleConstants.USER && pathname === '/reports')) {
      window.toastr.error('Unauthorised Access');
      return <Navigate to="/" replace />;
    }
    return children;
  }
  const localToken = localStorage.getItem('token');
  if (localToken) {
    dispatch(setAuth({ ...authStatus, isAuthenticated: true, token: localToken }));
    return children;
  }
  if (!authStatus.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
}

export default PR;
