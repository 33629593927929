/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useGetReportFieldsQuery, useGetTemplateConfigurationQuery } from '../../Redux/services/report';
import TemplateAndCustomizableFieldsContainer from './TemplateAndCustomizableFieldsContainer';
import ReportTableAndFilterFieldsContainer from './ReportTableAndFilterFieldsContainer';
import './Report.css';

function Reports() {
  const { data: customizableFieldsData } = useGetReportFieldsQuery();
  const [customFields, setCustomFields] = useState({});
  const [customCheckedFields, setCustomCheckedFields] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const { data: templateData } = useGetTemplateConfigurationQuery({ id: selectedTemplateId });
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (customizableFieldsData?.data) {
      setCustomFields(customizableFieldsData?.data);
    }
  }, [customizableFieldsData?.data]);

  function updateCustomFieldsForReportAndFilterChecks() {
    if (templateData?.data) {
      const newCustomFields = { ...customFields };
      Object.keys(newCustomFields).forEach((fieldName) => {
        const found = templateData?.data?.find((template) => template.fieldName === fieldName);
        newCustomFields[fieldName] = {
          ...newCustomFields[fieldName],
          reportChecked: found?.report,
          filterChecked: found?.filter,
        };
      });
      setCustomFields(newCustomFields);
    }
  }

  useEffect(() => {
    if (Object.keys(customFields).length !== 0 && templateData?.data) {
      updateCustomFieldsForReportAndFilterChecks();
    }
  }, [templateData?.data]);

  useEffect(() => {
    window.KTMenu.init();
  }, []);

  const handleCustomFieldChange = (fieldType, fieldName) => {
    const field = customFields[fieldName];
    if (!field) return;
    const updatedField = {
      ...field,
      [`${fieldType}Checked`]: !field[`${fieldType}Checked`],
    };

    setCustomFields((prevCustomFields) => ({
      ...prevCustomFields,
      [fieldName]: updatedField,
    }));
    setSelectedTemplateId('');
  };

  useEffect(() => {
    if (templateData?.data && customizableFieldsData?.data) {
      const customFieldsData = customizableFieldsData?.data;
      const fields = templateData?.data?.map((field) => field.fieldName);

      const filteredCustomFields = Object.keys(customFieldsData)?.filter((key) => {
        if (fields.includes(key)) {
          return customFieldsData[key].label;
        }
        return null;
      });

      const fieldConfig = templateData?.data?.map((field, index) => ({
        id: field.id,
        key: field.fieldName,
        filter: field.filter,
        report: field.report,
        label: filteredCustomFields[index],
      }));
      setCustomCheckedFields(fieldConfig);
    }

  }, [templateData?.data, customizableFieldsData?.data]);

  return (
    <>
      <TemplateAndCustomizableFieldsContainer
        handleCustomFieldChange={handleCustomFieldChange}
        customFields={customFields}
        customCheckedFields={customCheckedFields}
        setCustomCheckedFields={setCustomCheckedFields}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedTemplateId={selectedTemplateId}
        filtersData={filter}
      />
      <ReportTableAndFilterFieldsContainer
        customFields={customFields}
        customCheckedFields={customCheckedFields}
        selectedTemplateId={selectedTemplateId}
        setFilter={setFilter}
        filtersData={filter}
      />
    </>
  );
}
export default Reports;
