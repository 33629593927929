/* eslint-disable react/no-array-index-key */

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetMonthlyTimesheetQuery } from '../../Redux/services/timesheet';
import LogDetails from './LogDetails';

function Table({ filtersData, masterData }) {
  const { data: timesheetMonthlyData } = useGetMonthlyTimesheetQuery(filtersData);
  const [showlogDetailsModal, setShowlogDetailsModal] = useState(false);
  const [dataForLogDetails, setDataForlogDetails] = useState({});
  const [totalHoursPerDay, setTotalHoursPerDay] = useState({});
  const [totalHours, setTotalHours] = useState(0);

  const handleViewLogDetails = ({ project, user, date }) => {
    setDataForlogDetails({ project, user, date });
    setShowlogDetailsModal(true);

  };
  const onHideLogDetails = () => {
    setShowlogDetailsModal(false);
  };

  const calculateTotalHours = () => {
    const totals = {};
    timesheetMonthlyData?.data?.records?.forEach((record) => {
      totals.totalHours = (totals.totalHours || 0) + (record.totalHours || 0);
    });
    setTotalHours(totals?.totalHours);
  };

  const calculateTotalHoursPerDay = () => {
    const totals = {};
    timesheetMonthlyData?.data?.records?.forEach((record) => {
      record?.data?.forEach((item, j) => {
        if (j >= 3) {
          totals[j] = (totals[j] || 0) + (item.rowData || 0);
        }
      });
    });
    const hours = Object.fromEntries(Object.entries(totals)
      .map(([key, value]) => [key, parseFloat(value.toFixed(2))]));
    setTotalHoursPerDay(hours);
  };
  useEffect(() => {
    calculateTotalHoursPerDay();
    calculateTotalHours();

    // eslint-disable-next-line
  }, [timesheetMonthlyData?.data?.records]);

  return (
    <div className="card-body pt-0">
      <div className="table-outer-wrapper">
        <div className="scrolling-lock-table-wrapper">
          <div className="row g-0">
            <div className="col-4">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer tbl-search-results tbl-mobile-headers"
                id="tbl_mobile_headers_0"
              >
                <thead>
                  <tr className="text-start text-gray-800 fw-bold fs-7 text-uppercase gs-0">
                    {timesheetMonthlyData?.data?.headers?.map((item, i) => ((item?.heading
                      && i < 3) ? (
                        <th key={item?.heading}>
                          <Link to="#" className="sortable">{item?.heading} <br />
                            {item?.heading === 'Total' && <span className="text-muted">Hours</span>}
                          </Link>
                        </th>
                      ) : null))}
                  </tr>
                </thead>
                <tbody>
                  {timesheetMonthlyData?.data?.records?.map((record, i) => (
                    <tr key={i}>
                      {record?.data
                        ?.map((item, j) => ((item?.projectId || item?.userId || item?.totalHours)
                          ? (
                        // eslint-disable-next-line react/no-array-index-key
                            <td key={j} className="text-start text-gray-500 fw-bold fs-7 gs-0">
                              {item?.rowData}
                            </td>
                          ) : null))}
                    </tr>
                  ))}
                  <tr>
                    <td aria-label="empty-cell" />
                    <td className="text-start text-gray-500 fw-bold fs-7 gs-0">Total</td>
                    <td>{ totalHours }</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div className="col-8">

              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer tbl-search-results tbl-mobile-headers"
                id="tbl_mobile_headers_0"
              >
                <thead>
                  <tr className="text-start text-gray-800
                   fw-bold fs-7 text-uppercase gs-0"
                  >
                    { timesheetMonthlyData?.data?.headers
                      ?.map((item) => (item?.heading ? null : (
                        <th style={{ background: 'var(--bs-body-bg)' }} key={item?.date} className="text-start text-gray-500 fw-bold fs-7 gs-0">
                          <Link to="#" className="sortable">{item?.date}
                            <br />
                            {item?.day}
                          </Link>
                        </th>
                      )))}
                  </tr>
                </thead>
                <tbody>
                  {timesheetMonthlyData?.data?.records?.map((record, i) => (
                    <tr key={i}>
                      {record?.data
                        ?.map((item, j) => ((j > 2)
                          && (
                            <td key={j} style={{ background: 'var(--bs-body-bg)' }} className="cursor-pointer" onClick={() => handleViewLogDetails({ project: record?.projectId, user: record?.userId, date: item?.date })}>{item?.rowData}
                            </td>
                          )))}
                    </tr>
                  ))}
                </tbody>
                {timesheetMonthlyData?.data?.records?.length !== 0 && (
                  <tr className="text-start text-gray-800 fw-bold fs-7 text-uppercase gs-0">
                    {Object.keys(totalHoursPerDay)
                      ?.map((key, index) => ((
                        <td style={{ background: 'var(--bs-body-bg)' }} key={index} className="text-start text-gray-500 fw-bold fs-7 gs-0">
                          {totalHoursPerDay[key]}
                        </td>
                      )
                      ))}
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      {showlogDetailsModal && (
        <LogDetails
          showlogDetailsModal={showlogDetailsModal}
          setShowLogDetails={setShowlogDetailsModal}
          onHide={onHideLogDetails}
          dataForLogDetails={dataForLogDetails}
          masterData={masterData}
        />
      )}
    </div>
  );
}

export default Table;
